import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import { Button, ChevronEndIcon, ChevronStartIcon, Flex, FlexItem, Loader, Provider, Ref, teamsDarkTheme, teamsHighContrastTheme, teamsTheme, Text } from "@fluentui/react-northstar";
import moment from "moment";
//import "../../assets/scss/app.scss";
//import "../../assets/scss/calendar.scss";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import timeZonePlugin from "@fullcalendar/moment-timezone";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { showErrorPage } from "@witivio_teamspro/witivio-react-toolkit";
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import deLocale from '@fullcalendar/core/locales/de';
import itLocale from '@fullcalendar/core/locales/it';
import ptLocale from '@fullcalendar/core/locales/pt';
import { translations } from "../../translations";
import { getCalendar } from "../../apis/apiCalendar";
import { getResource } from "../../apis/apiResource";
import { getEvents } from "../../apis/apiEvent";
import { formatDatabaseEventToFullCalendar } from "../../components/calendar/Calendar.utils";
import { useTranslate } from "front";
/**
 * Calendar Pro widget
 * @constructor
 */
export const Widget = () => {
    const [context, setContext] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [calendarId, setCalendarId] = useState("");
    const [events, setEvents] = useState(new Array());
    const [resources, setResources] = useState(new Array());
    const [calendar, setCalendar] = useState(undefined);
    const [calendarTitle, setCalendarTitle] = useState("");
    const t = useTranslate(translations);
    const containerRef = useRef(null);
    const fullcalendarRef = useRef(null);
    const navbarRef = useRef(null);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /**
     * Refresh timeout
     */
    let refreshTimeout = undefined;
    /**
     * Is fetching events ?
     */
    let isFetchingEvents = false;
    /**
     * Initialization
     */
    useEffect(() => {
        (async () => {
            HomePro.context.registerThemeChangeHandler(theme => {
                setTheme(theme);
                setContext(prevContext => !!prevContext ? ({ ...prevContext, themeName: theme }) : undefined);
            });
            const teamsContext = await HomePro.context.getContext();
            setTheme(teamsContext.themeName);
            setContext(teamsContext);
            const calendarId = (await HomePro.settings.getSettings()).id;
            setCalendarId(calendarId);
            const localEvents = retrieveLocalEvents(calendarId);
            setEvents(localEvents);
            const localResources = retrieveLocalResources(calendarId);
            setResources(localResources);
            const localCalendar = retrieveLocalCalendar(calendarId);
            setCalendar(localCalendar);
            const calendarTitle = retrieveCalendarTitle();
            setCalendarTitle(calendarTitle);
            if (localEvents.length > 0)
                setLoading(false);
            const calendar = await fetchCalendar(calendarId);
            if (calendar.id !== calendarId) {
                await showErrorPage(t("CalendarDeleted"));
                return;
            }
            setCalendar(calendar);
        })();
    }, []);
    /**
     * On calendar loaded
     */
    useEffect(() => {
        (async () => {
            if (!calendar)
                return;
            const resources = calendar.isResourceMode ?
                await fetchResources(calendar.id ?? "") : new Array();
            setResources(resources);
            await refreshEvents();
            setLoading(false);
        })();
    }, [calendar]);
    /**
     * Retrieve local events
     */
    const retrieveLocalEvents = (calendarId) => {
        if (!!localStorage) {
            const stringEvents = localStorage.getItem("events_" + calendarId);
            if (!!stringEvents)
                return JSON.parse(stringEvents);
        }
        return new Array();
    };
    /**
     * Retrieve local calendar
     */
    const retrieveLocalCalendar = (calendarId) => {
        const calendar = JSON.parse(localStorage?.getItem("calendar_" + calendarId) ?? "{}");
        if (!calendar.id)
            return undefined;
        return calendar;
    };
    /**
     * Retrieve local resources
     */
    const retrieveLocalResources = (calendarId) => {
        if (!!localStorage) {
            const stringResources = localStorage.getItem("resources_" + calendarId);
            if (!!stringResources)
                return JSON.parse(stringResources);
        }
        return new Array();
    };
    /**
     * Set theme
     * @param theme theme
     */
    const setTheme = (theme) => {
        switch (theme) {
            case "default":
                document.body.id = "teamsDefault";
                break;
            case "dark":
                document.body.id = "teamsDark";
                break;
            case "contrast":
                document.body.id = "teamsHighContrast";
                break;
        }
    };
    /**
     * Retrieve calendar title from current displayed month / week / day
     */
    const retrieveCalendarTitle = () => {
        if (!fullcalendarRef.current)
            return "";
        let calendarApi = fullcalendarRef.current.getApi();
        return calendarApi.view.title;
    };
    /**
     * Show previous events
     */
    const showPreviousEvents = async () => {
        if (!fullcalendarRef?.current)
            return;
        let calendarApi = fullcalendarRef.current.getApi();
        calendarApi.prev();
        setCalendarTitle(retrieveCalendarTitle());
        await refreshEvents();
    };
    /**
     * Show next events
     */
    const showNextEvents = async () => {
        if (!fullcalendarRef?.current)
            return;
        let calendarApi = fullcalendarRef.current.getApi();
        calendarApi.next();
        setCalendarTitle(retrieveCalendarTitle());
        await refreshEvents();
    };
    /**
     * Disable today button if current events are from today
     */
    const isTodayButtonDisabled = () => {
        if (!!fullcalendarRef.current) {
            let calendarApi = fullcalendarRef.current.getApi();
            let today = new Date();
            if (calendarApi.view.activeStart >= today && today <= calendarApi.view.activeEnd
                || calendarApi.view.activeStart <= today && today >= calendarApi.view.activeEnd)
                return false;
        }
        return true;
    };
    /**
     * Show today events
     */
    const showTodayEvents = async () => {
        if (!fullcalendarRef.current)
            return;
        let calendarApi = fullcalendarRef.current.getApi();
        calendarApi.today();
        setCalendarTitle(retrieveCalendarTitle());
        await refreshEvents();
    };
    /**
     * Fetch calendar
     */
    const fetchCalendar = async (calendarId) => {
        const calendar = await getCalendar(calendarId, true);
        if (!!localStorage && calendar.id === calendarId)
            localStorage.setItem("calendar_" + calendarId, JSON.stringify(calendar));
        return calendar;
    };
    /**
     * Fetch calendar resources
     */
    const fetchResources = async (calendarId) => {
        const resource = await getResource(calendarId);
        const resources = resource.resourceData ?? new Array();
        if (!!localStorage && !!resources)
            localStorage.setItem("resources_" + calendarId, JSON.stringify(resources));
        return resources;
    };
    /**
     * Fetch calendar events
     * @param calendarId calendar id
     * @param tagFilter tag filter
     */
    const fetchEvents = async (calendarId, tagFilter) => {
        if (!fullcalendarRef.current)
            return new Array();
        let calendarApi = fullcalendarRef.current.getApi();
        let startDay = moment(calendarApi.currentDataManager?.getCurrentData().viewApi.activeStart).format("YYYY-MM-DD");
        let endDay = moment(calendarApi.currentDataManager?.getCurrentData().viewApi.activeEnd).format("YYYY-MM-DD");
        const newEvents = await getEvents(calendarId, calendar?.isResourceMode ?? false, startDay, endDay, tagFilter);
        let localEvents = JSON.parse(localStorage?.getItem("events_" + calendarId) ?? "[]");
        localEvents = Array.from(new Map([...localEvents, ...newEvents].map(e => [e.id, e])).values());
        if (!!localStorage)
            localStorage.setItem("events_" + calendarId, JSON.stringify(localEvents));
        return newEvents;
    };
    /**
     * Refresh calendar events
     */
    const refreshEvents = async () => {
        if (isFetchingEvents || !!refreshTimeout) {
            if (!!refreshTimeout)
                clearTimeout(refreshTimeout);
            refreshTimeout = setTimeout(async () => {
                setEvents(await fetchEvents(calendarId));
                isFetchingEvents = false;
            }, 500);
        }
        else {
            isFetchingEvents = true;
            setEvents(await fetchEvents(calendarId));
            isFetchingEvents = false;
        }
    };
    /**
     * Get current theme
     */
    const getCurrentTheme = () => {
        let theme = teamsTheme;
        switch (context?.themeName) {
            case "dark":
                theme = teamsDarkTheme;
                break;
            case "contrast":
                theme = teamsHighContrastTheme;
                break;
        }
        return theme;
    };
    /**
     * Get calendar list height
     */
    const getCalendarListHeight = () => {
        const containerHeight = containerRef.current?.offsetHeight ?? 0;
        if (containerHeight === 0)
            return 0;
        const navbarHeight = navbarRef.current?.offsetHeight ?? 0;
        if (navbarHeight === 0)
            return 0;
        // We substract 10px of margin
        return containerHeight - navbarHeight - 10;
    };
    /**
     * On select event
     * @param fullCalendarEvent fullcalendar event
     */
    const onSelectEvent = async (fullCalendarEvent) => {
        const eventId = fullCalendarEvent.event.id;
        await HomePro.popup.start({
            title: fullCalendarEvent.event.title,
            url: process.env.REACT_APP_APIBaseUrl + "/widget/details?calendarId=" + calendarId + "&eventId=" + eventId,
            height: 500,
            width: 600
        });
    };
    /**
     * Render navigation bar
     */
    const renderNavBar = () => {
        return (_jsx(Ref, { innerRef: navbarRef, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsxs(Flex, { children: [_jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: showPreviousEvents, styles: { marginRight: "5px" } }), _jsx(Button, { icon: _jsx(ChevronEndIcon, {}), iconOnly: true, onClick: showNextEvents })] }), _jsx(Button, { primary: true, styles: { minWidth: "auto" }, content: t("Today"), disabled: isTodayButtonDisabled(), onClick: showTodayEvents }), _jsx(FlexItem, { push: context?.onMobile, children: _jsx(Text, { styles: { whiteSpace: "nowrap" }, weight: "bold", content: calendarTitle }) })] }) }));
    };
    return useMemo(() => {
        const theme = getCurrentTheme();
        const calendarContentHeight = getCalendarListHeight();
        return (_jsx(Provider, { theme: theme, children: _jsxs("div", { ref: containerRef, className: "fill-absolute", style: { padding: "0 10px" }, children: [loading ?
                        _jsx(Flex, { vAlign: "center", hAlign: "center", children: _jsx(Loader, { className: "fill-absolute" }) })
                        :
                            null, _jsxs(Flex, { fill: true, column: true, gap: "gap.small", styles: { visibility: !loading && calendarContentHeight > 0 ? "visible" : "hidden" }, children: [renderNavBar(), _jsx(Flex, { column: true, fill: true, id: "fullcalendar", styles: { maxHeight: calendarContentHeight, overflow: "scroll" }, children: _jsx(FullCalendar, { ref: fullcalendarRef, headerToolbar: false, schedulerLicenseKey: process.env.REACT_APP_APICalendar, plugins: [listPlugin, scrollGridPlugin, rrulePlugin, timeZonePlugin], initialView: "listWeek", timeZone: timeZone, contentHeight: calendarContentHeight, events: events.map(e => formatDatabaseEventToFullCalendar(e, events, timeZone)), resources: resources, eventClick: onSelectEvent, eventMinWidth: 80, slotMinWidth: 80, resourceAreaWidth: 80, dayMinWidth: 80, locale: context?.locale ?? "en", locales: [esLocale, frLocale, deLocale, itLocale, ptLocale] }) })] })] }) }));
    }, [context?.themeName, loading, containerRef.current, events, calendarTitle]);
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Checkbox, CloseIcon, Flex, FlexItem, FormDropdown, FormInput, Header, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import { useDetailsPanelLogic } from "./DetailsPanel.logic";
import { findKeyPath, useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../../translations";
import { Controller } from "react-hook-form";
import { useState } from "react";
import { SketchPicker } from "react-color";
export const DetailsPanel = (props) => {
    const logic = useDetailsPanelLogic(props);
    const t = useTranslate(translations);
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const styles = {
        color: ({
            width: '14px',
            height: '14px',
            borderRadius: '2px',
            background: props.watchData[findKeyPath(props.keysRegistered, "eventColor")] ?? "#6264a7",
        }),
        swatch: {
            width: "fit-content",
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: !userPerm.perm.allowEditMeetingScheduler ? 'default' : 'pointer',
        },
        popover: {
            position: "static",
            zIndex: '2',
            width: "fit-content",
        },
        cover: {
            float: "right",
            marginRight: "5px",
            marginTop: "5px",
            cursor: !userPerm.perm.allowEditMeetingScheduler ? "default" : "pointer",
            color: "black"
        },
    };
    document.onclick = (e) => {
        const popoverColorPicker = document.getElementById('sync-popover-color-picker');
        if (isColorPickerVisible &&
            e.target.id != 'sync-color-picker-button' &&
            e.target.id != 'sync-color-picker-buttoncolor' &&
            e.target.id != 'sync-popover-color-picker' &&
            popoverColorPicker?.contains(e.target) == false) {
            setIsColorPickerVisible(false);
        }
        const dropdownOpen = document.getElementById("dropdown-hosts");
        if (e.target.id === "dropdown-hosts") {
            logic.setIsDropdownHostsOpen(true);
        }
        else if (logic.isDropdownHostsOpen) {
            if (!e.target.id.includes("dropdown-list-hosts") && dropdownOpen?.contains(e.target) === false) {
                logic.setIsDropdownHostsOpen(false);
            }
        }
    };
    const titleInput = () => (_jsx(Flex, { styles: { maxWidth: "fit-content", paddingBottom: "10px", marginLeft: "20px" }, children: _jsx(Controller, { name: findKeyPath(props.keysRegistered, "title"), control: props.control, rules: { required: true, maxLength: 70 }, render: ({ field }) => (_jsx(FormInput, { styles: {
                    width: "300px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontWeight: "semibold"
                }, inverted: true, label: _jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingSchedulerTitle"), weight: "semibold", styles: { marginRight: "0px !important" } }), _jsx(Text, { content: "*", color: "red" }), _jsx(Tooltip, { content: t("MeetingSchedulerTitleInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), fluid: true, ...field, onChange: (_, data) => field.onChange(data.value), error: true, errorMessage: (field.value === undefined || field.value.trim() === "") || (field.value.length > 70)
                    ? (field.value === undefined || field.value.trim() === "")
                        ? t("NoTitle")
                        : t("TooManyCharacters")
                    : null, "data-testid": "details-panel-title", disabled: !userPerm.perm.allowEditMeetingScheduler }, `meeting-scheduler-input-title`)) }, "title_controller") }));
    const companyNameInput = () => (_jsx(Flex, { styles: { maxWidth: "fit-content", paddingBottom: "10px", marginLeft: "20px" }, children: _jsx(Controller, { name: findKeyPath(props.keysRegistered, 'companyName'), control: props.control, rules: { maxLength: 70 }, render: ({ field }) => (_jsx(FormInput, { styles: {
                    width: "300px",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontWeight: "semibold"
                }, inverted: true, label: _jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingSchedulerCompanyName"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingSchedulerCompanyNameInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), fluid: true, ...field, onChange: (_, data) => field.onChange(data.value), error: true, errorMessage: field.value.length > 70 ? t("TooManyCharacters") : null, "data-testid": "details-panel-company-name", disabled: !userPerm.perm.allowEditMeetingScheduler }, `calendly-input-company-name`)) }, "company_name_controller") }));
    const eventColor = () => (_jsxs(Flex, { styles: { paddingBottom: "10px", marginLeft: "20px", textAlign: 'center', justifyContent: 'center' }, column: true, gap: "gap.small", children: [_jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingSchedulerEventColor"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingSchedulerEventColorInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), !isColorPickerVisible && userPerm.perm.allowEditMeetingScheduler ?
                _jsx("div", { id: 'sync-color-picker-button', style: styles.swatch, onClick: () => { setIsColorPickerVisible(true); }, children: _jsx("div", { id: 'sync-color-picker-buttoncolor', style: styles.color, onClick: () => setIsColorPickerVisible(true), "data-testid": "details-panel-color" }) })
                :
                    _jsx("div", { id: 'sync-color-picker-button', style: styles.swatch, children: _jsx("div", { id: 'sync-color-picker-buttoncolor', style: styles.color, "data-testid": "details-panel-color" }) }), isColorPickerVisible &&
                _jsxs("div", { style: styles.popover, id: 'sync-popover-color-picker', children: [_jsx("div", { style: styles.cover, onClick: () => { setIsColorPickerVisible(false); }, children: _jsx(CloseIcon, { size: "small" }) }), _jsx(SketchPicker, { disableAlpha: true, ...props.register(findKeyPath(props.keysRegistered, 'eventColor')), color: props.watchData[findKeyPath(props.keysRegistered, "eventColor")], presetColors: ['red', 'orange', 'gold', 'green', 'teal', 'blue', 'darkBlue', 'indigo', 'plum', 'purple'], onChange: (color) => logic.handleColorOnChange(color.hex) })] })] }));
    const durationDropdown = () => (_jsx(Flex, { styles: { maxWidth: "fit-content", paddingTop: "10px", paddingBottom: "10px", marginLeft: "20px" }, column: true, gap: "gap.small", hAlign: "center", vAlign: "center", children: _jsx(FormDropdown, { fluid: true, inverted: true, label: _jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingScheduler:DurationComponent"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingScheduler:DurationInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), defaultSearchQuery: logic.selectedDurationItem.header, defaultValue: logic.selectedDurationItem, items: logic.durationItems, ...props.register(findKeyPath(props.keysRegistered, 'duration')), onChange: logic.onSelectDuration, "data-testid": "details-panel-duration", disabled: !userPerm.perm.allowEditMeetingScheduler }, "duration-meeting-scheduler") }));
    const hostsDropdown = () => (_jsxs(Flex, { styles: { maxWidth: isOnMobile ? "200px" : "fit-content", paddingTop: "10px", paddingBottom: "10px", marginLeft: "20px" }, column: true, gap: "gap.small", children: [_jsx(Controller, { name: findKeyPath(props.keysRegistered, "hosts"), control: props.control, render: ({ field }) => (_jsx(FormDropdown, { id: "dropdown-hosts", fluid: true, search: true, inverted: true, styles: {
                        /*value: {
                            fontWeight: "bold !important"
                        },
                        dropdownItem: {
                            maxHeight: '30px',
                            overflowY: 'auto',
                        },*/
                        width: isOnMobile ? "300px" : "400px"
                    }, label: _jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingScheduler:HostsComponent"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingScheduler:HostsInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), searchQuery: logic.searchQuery, onSearchQueryChange: (_, data) => { logic.setSearchQuery(data.searchQuery); }, placeholder: t("MeetingScheduler:HostsPlaceHolder"), items: logic.filteredUsers.map((u, index) => ({
                        id: "dropdown-list-hosts",
                        header: u.name,
                        key: index,
                        content: (_jsxs(Flex, { vAlign: "center", children: [_jsx(FlexItem, { styles: { paddingLeft: '5px' }, children: _jsx(Text, { content: u.name }) }), _jsx(FlexItem, { push: true, children: _jsx(Checkbox, { checked: props.watchData[findKeyPath(props.keysRegistered, "hosts")]?.find((h) => h.id === u.id), onChange: (_, data) => { logic.onSelectHosts(u, field, data); } }) })] }, index))
                    })), open: logic.isDropdownHostsOpen, onOpenChange: () => logic.setIsDropdownHostsOpen(true), onChange: () => { logic.setSearchQuery(logic.searchQuery); }, noResultsMessage: t("NoHost"), "data-testid": "details-panel-hosts", disabled: !userPerm.perm.allowEditMeetingScheduler }, "hosts-meeting-scheduler")) }, "hosts_controller"), _jsx("div", { style: { marginTop: "4px" }, children: props.watchData[findKeyPath(props.keysRegistered, "hosts")]?.map((p, index) => _jsx(Tooltip, { content: p.displayName, children: _jsx(Avatar, { style: { marginRight: "5px" }, size: "smaller", name: p.displayName }) }, index)) })] }));
    return (_jsx(FlexItem, { children: _jsxs(Flex, { column: true, gap: "gap.medium", styles: {
                display: "flex",
                width: isOnMobile ? "95vw" : "auto",
                marginLeft: "20px",
                overflowY: isOnMobile ? "scroll" : "hidden"
            }, children: [_jsx(Header, { as: "h4", content: t("MeetingScheduler:BookingAvailabilityTitle"), styles: { paddingBottom: "10px" } }), titleInput(), companyNameInput(), eventColor(), durationDropdown(), hostsDropdown()] }) }));
};

import { OptionsReducerState, setNavbar, setOptions, translations } from "calendarShared";
import { AppViews } from "const/AppViews";
import { setInfos } from "front";
import { queryClient } from "index";
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { translationsSpecific } from "translations";

interface ITranslation {
    [key:string]: any
}

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const useConfig = () => {
    const dispatch = useDispatch();

    const manageTranslations = () => {
        Object.keys(translationsSpecific).forEach(t => {
            (translations as ITranslation)[t] = (translationsSpecific  as ITranslation)[t]
        })
    }

    useEffect(()=>{
        const config : OptionsReducerState = {
            allowShowWeekend : true,
            allowShowNumberOfWeek : false,
            allowBuisnessDay : false,
            allowPublicHolidays : false,
            allowPerms : true,
            allowSync : false,
            forceRessource : true,
            allowNotification : true,
            allowAddFile : true,
            allowMeetingScheduler: false,
            allowCopy : false,
            allowWhatNew : false,
            allowDoubleBooking : false, 
            nameApp: "Booking"
        }
        manageTranslations()
        dispatch(setInfos({appViews: AppViews, queryClient, appInsightInstance: null}))
        dispatch(setNavbar({displayMode: 3, searchTag: "", calendarTimeZone: localTimezone}))
        dispatch(setOptions(config))
    },[])
}
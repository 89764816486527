import React from 'react';
import {
    Button,
    Flex,
    FormInput,
    Header,
    Loader,
    OptionsIcon,
    Text,
    Image
} from '@fluentui/react-northstar';
import * as microsoftTeams from "@microsoft/teams-js";
import 'moment/min/locales'
import SaveEvent = microsoftTeams.settings.SaveEvent;
import { BasicResource, ICalendar, IResource, Owner, ResourcesView, translations, useConfigLogic } from 'calendarShared';
import { useMsTeamsSelector, useTranslate } from 'front';

export interface IConfigurationState {
    basicResources: Array<BasicResource>;
    loaded: boolean;
    calendar: ICalendar;
    showResourcesView: boolean;
    resource: IResource;
    weekdays: Array<string | undefined>;
    allUsers: Array<any>;
    ownersSelected: Array<Owner>;
    readOnly: boolean;
}

export const Configuration = () =>{
    const logic = useConfigLogic();
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const t = useTranslate(translations)
    if (!logic.data.loaded) return <Loader style={{height: "100vh", width: "100vw"}}/>
    const renderInitialConfigurationScreen = () => {
        const applicationWelcome = (
            <Flex column>
            <Header 
                as={"h3"}
                styles={{
                textAlign: "center",
                }}
                content={t("WelcomeMessage")}>
            </Header>
            </Flex>
        );
        
            const calendarNameInput = (
                <FormInput
                label={
                  <Flex>
                    {t("Name")}
                    <Text styles={{ marginLeft: "3px" }} content={"*"} color={"red"} />
                  </Flex>
                }
                fluid
                errorMessage={
                  !logic.data.calendar.name
                    ? t("EmptyFillError")
                    : ""
                }
                placeholder={t("AddName")}
                value={logic.data.calendar.name ?? ""}
                onChange={logic.onChangeCalendarName}
              />
            );
        
            const calendarTypeDropdown = (
                <Flex hAlign={"end"}>
                    <Button
                        styles={{marginTop: "10px"}}
                        icon={<OptionsIcon outline/>}
                        disabled={logic.data.readOnly}
                        primary
                        content={t("ButtonContentResource")}
                        onClick={() => logic.showResourcesView(true)}/>
                </Flex>
            );
        
            return (
              <Flex
                fill
                column
                gap={"gap.medium"}
                styles={{
                  display: logic.data.showResourcesView ? "none" : "flex",
                  padding: isOnMobile ? "15px" : "0",
                  overflowY: "scroll",
                }}
              >
                {applicationWelcome}
                {calendarNameInput}
                {calendarTypeDropdown}
              </Flex>
            );
          };
        
        
        
        
          /**
           * Render configuration screen
           */
          const renderConfiugrationScreen = () => {
        
        
            const renderHelp = (
                <Flex column gap={"gap.small"} style={{
                    marginTop: "20px"
                    }}>
                    <Text 
                    styles={{
                        textAlign: "center",
                    }}
                    content={t("ConfigurationHelp")}>
                    </Text>
                    <Image src={"/images/configuration/help.webp"} />
                </Flex>
              );
        
            return (
              <Flex
                fill
                column
                gap={"gap.medium"}
                styles={{
                  display: logic.data.showResourcesView ? "none" : "flex",
                  padding: isOnMobile ? "15px" : "0",
                  overflowY: "scroll",
                }}
              >
                {renderHelp}
              </Flex>
            );
          };
        
            /**
             * Render resources screen
             */
            const renderResourcesScreen = () => (
                <ResourcesView
                    calendar={logic.data.calendar}
                    show={logic.data.showResourcesView}
                    onHide={() => logic.showResourcesView(false)}
                    resources={logic.data.basicResources}
                    onChange={(resources: Array<BasicResource>) => logic.updatebasicResource(resources)}
                />
            )
        return (
            <Flex fill className={"fill-absolute"}>
                {!logic.data.calendar.id && renderInitialConfigurationScreen()}
                {logic.data.calendar.id && renderConfiugrationScreen()}
                {renderResourcesScreen()}
            </Flex>
        );
}


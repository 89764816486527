import { AxiosConfig } from "./AxiosConfig/AxiosConfig";
export const GetCalendarSyncByIcsUrl = (url) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.post(`/calendarsync/`, url);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get Calendar Sync by ics url");
    }
});

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { timeZones, useLogic } from "./Navbar.logic";
import { AddIcon, Button, CalendarIcon, Checkbox, ChevronEndIcon, ChevronStartIcon, Dropdown, Flex, FlexItem, MenuButton, Ref, Text, Tooltip } from "@fluentui/react-northstar";
import { AboutDialog, InformationMenu, NotificationsDIalog, UpgradeDialog, useAppConfigurationSelector, useConfigurationSelector, useModalSelector, useMsTeamsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, } from "front";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { SecurityMode } from "../../../interfaces/Enums";
import { useNavBarSelector } from "../../../redux/reducers/navbarReducer";
import { Settings24Regular } from "@fluentui/react-icons";
import Frame from "react-frame-component";
import { Modal } from "../../../interfaces/modalInterface";
import { SettingsDialog } from "../../dialogs/Settings/Settings.dialog";
import { ResourcesDialog } from "../../dialogs/Resources/Resource.dialog";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { translations } from "../../../translations";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export const CalendarNavbar = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const navigation = useNavigate();
    const { appInsightInstance } = useSelector((s) => s.infosApp);
    const { allowSync } = useOptionsSelector("allowSync");
    const { isOpen } = useModalSelector("isOpen");
    const { data: config } = useConfigurationSelector("data");
    const { isResourceMode } = useAppConfigurationSelector("isResourceMode");
    const { isOnMobile, userMail } = useMsTeamsSelector("isOnMobile", "userMail");
    const { isPremium, isPlatinum } = usePremiumSelector("isPremium", "isPlatinum");
    const { searchTag, calendarTimeZone } = useNavBarSelector("searchTag", "calendarTimeZone");
    const { calendar } = useCalendarCache();
    const { userPerm } = usePermissionsSelector("userPerm");
    const styles = {
        color: (index) => ({
            width: "1rem",
            height: "1rem",
            borderRadius: "2px",
            padding: "0.125rem",
            background: props.displayedCalendars[index]?.color ?? "#6264a7",
        }),
        swatch: {
            background: "#fff",
            borderRadius: "2px",
            marginTop: "0.175rem",
            border: "0.0625rem solid rgb(91, 95, 199)",
            display: "inline-block",
            cursor: "pointer",
        },
    };
    document.onclick = (e) => {
        const dropdownOpen = document.getElementById("dropdown-shared-calendars");
        if (e.target.id === "dropdown-shared-calendars") {
            logic.setIsDropdownSharedCalendarsOpen(true);
        }
        else if (logic.isDropdownSharedCalendarsOpen) {
            if (!e.target.id.includes("dropdown-list-shared-calendars") && dropdownOpen?.contains(e.target) === false) {
                logic.setIsDropdownSharedCalendarsOpen(false);
            }
        }
    };
    const calendarNavigation = useMemo(() => (_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsxs(Flex, { children: [_jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: logic.showPreviousEvents, styles: { marginRight: "5px" } }), _jsx(Button, { icon: _jsx(ChevronEndIcon, {}), iconOnly: true, onClick: logic.showNextEvents })] }), _jsx(Button, { primary: true, styles: { minWidth: "auto" }, content: t("Today"), 
                //disabled={logic.isTodayButtonDisabled()}
                onClick: logic.showTodayEvents }), _jsx(FlexItem, { push: isOnMobile, children: _jsx(Text, { styles: { whiteSpace: "nowrap" }, weight: "bold", content: props.currentCalendarTitle }) })] })), [props.currentCalendarTitle, logic.showPreviousEvents, logic.showNextEvents, logic.isTodayButtonDisabled, logic.showTodayEvents, t, isOnMobile]);
    const securityModeAlert = useMemo(() => calendar?.securityMode === SecurityMode.OnlyMeCanEdit && calendar.createdBy?.toLowerCase() !== userMail.toLowerCase() ? (_jsx(Text, { weight: "semibold", content: (calendar?.createdBy ?? "") + t("MessageOnlyMePrevent") })) : null, [calendar, userMail, t]);
    const calendarsDropdownFilter = useMemo(() => (_jsx(Dropdown, { id: "dropdown-shared-calendars", value: props.displayedCalendars && props.displayedCalendars.length > 0
            ? `${t("CalendarsTitle")} ${props.displayedCalendars.filter((c) => c.display).length}/${props.displayedCalendars.length}`
            : `${t("CalendarsTitle")} 0/0`, items: props.displayedCalendars.map((calendar, index) => ({
            id: "dropdown-list-shared-calendars",
            key: index,
            content: (_jsxs(Flex, { vAlign: "center", children: [_jsx(FlexItem, { children: _jsx("div", { id: "sync-color-picker-button", style: styles.swatch, children: _jsx("div", { id: "sync-color-picker-buttoncolor", style: styles.color(index) }) }) }), _jsx(FlexItem, { styles: { paddingLeft: "5px" }, children: _jsx(Text, { content: calendar.name }) }), _jsx(FlexItem, { push: true, children: _jsx(Checkbox, { checked: props.displayedCalendars.find((c) => c.id === calendar.id).display, onChange: () => logic.handleSelectedCalendars(calendar) }) })] }, index)),
        })), disabled: !isPlatinum, inverted: true, fluid: true, styles: {
            value: {
                fontWeight: "bold !important",
            },
            dropdownItem: {
                maxHeight: "30px",
                overflowY: "auto",
            },
        }, open: logic.isDropdownSharedCalendarsOpen, onOpenChange: () => logic.setIsDropdownSharedCalendarsOpen(true) })), [isPlatinum, t, props.displayedCalendars, logic.isDropdownSharedCalendarsOpen, logic.setIsDropdownSharedCalendarsOpen, logic.onDropdownSharedCalendarsToggle, logic.handleSelectedCalendars]);
    const timezoneDropdownFilter = useMemo(() => (_jsx(Dropdown, { id: "dropdown-tz", search: true, fluid: true, inverted: true, items: timeZones, placeholder: calendarTimeZone, searchQuery: logic.searchQuery, onSearchQueryChange: logic.changeSearchQuery, onChange: logic.onChangeCalendarTimeZone })), [timeZones, calendarTimeZone, logic.onChangeCalendarTimeZone]);
    const tagsDropdownFilter = useMemo(() => (_jsx(Tooltip, { content: !isPremium && t("TagsPremium", { App: t("TitleCalendarPro") }), trigger: _jsx(Dropdown, { search: isPremium, fluid: true, inverted: true, disabled: !isPremium, items: calendar?.tags ?? [], placeholder: searchTag.trim() != "" ? searchTag : t("TagsTitle"), searchQuery: logic.tagsQuery, onSearchQueryChange: logic.changeTagsQuery, onChange: logic.onFilterEventsByTag, clearable: true, noResultsMessage: calendar?.tags?.length > 0 ? t("NoTagFound") : _jsx("div", { onClick: () => navigation("/tags/" + calendar?.id), children: t("noTag") }) }) })), [isPremium, calendar, t, logic.onFilterEventsByTag, searchTag]);
    const newEventButton = useMemo(() => {
        return props.userCanEditCalendar ? (_jsx(Button, { styles: { minWidth: "auto" }, primary: true, disabled: !userPerm.perm.add, content: t("AddEvent"), icon: _jsx(AddIcon, {}), onClick: props.onCreateNewEvent })) : null;
    }, [props.userCanEditCalendar, props.onCreateNewEvent, t]);
    const viewModeButton = useMemo(() => (_jsx(MenuButton, { styles: { width: isOnMobile ? "100%" : "auto" }, trigger: _jsx(Button, { fluid: isOnMobile, primary: true, icon: _jsx(CalendarIcon, {}), content: logic.getTranslatedCurrentDisplayMode() }), menu: {
            styles: isOnMobile ? { minWidth: "calc(100vw - 20px)" } : {},
            fluid: isOnMobile,
            items: logic.displayModesDropdownItems ?? [],
        }, onMenuItemClick: logic.onChangeDisplayMode })), [isOnMobile, logic.displayModesDropdownItems, logic.getTranslatedCurrentDisplayMode, logic.onChangeDisplayMode]);
    const moreOptionsButton = useMemo(() => _jsx(MenuButton, { trigger: _jsx(Button, { secondary: true, iconOnly: true, icon: _jsx(Settings24Regular, {}) }), menu: logic.moreOptionsDropdownItems, onMenuItemClick: logic.onClickMoreOptionsAction }), [logic.moreOptionsDropdownItems, logic.onClickMoreOptionsAction]);
    const helpButton = () => _jsx(InformationMenu, {});
    /**
     * Render iframe to print calendar
     */
    const renderPrintIframe = () => {
        if (!logic.state.imageToPrint)
            return;
        return !isOnMobile ? (_jsxs(Frame, { id: "printContentIframe", name: "printContentIframe", style: { display: "none" }, children: [_jsxs("div", { style: { marginBottom: 10 }, children: [calendar?.name, " - ", props.currentCalendarTitle] }), _jsx("img", { style: { width: "100%", height: "100%" }, src: logic.state.imageToPrint, alt: "imagePrint" })] })) : null;
    };
    const renderSettingsDialog = () => {
        if (isOpen != Modal.Settings)
            return null;
        return _jsx(SettingsDialog, { refreshPublicHolidays: props.refreshPublicHolidays, onHide: logic.closeSettings });
    };
    const renderRoomsDialog = () => {
        if (isOpen != Modal.Rooms)
            return null;
        return _jsx(ResourcesDialog, {});
    };
    /**
     * Render dialog to get app details
     */
    const renderGetIdDialog = () => {
        if (isOpen != Modal.SHOWGETIDDIALOG)
            return;
        return _jsx(AboutDialog, {});
    };
    /**
     * Render dialog to get app details
     */
    const renderNotificationDialog = () => {
        if (isOpen != Modal.NOTIFICATIONS)
            return;
        return _jsx(NotificationsDIalog, { firstCheckBox: t("AddUpdateNotif"), firstCheckBoxDesc: t("AddUpdateNotifDesc"), secondCheckBox: t("DeleteNotif"), secondCheckBoxDesc: t("DeleteNotifDesc") });
    };
    const renderUpgradeDialog = () => {
        if (isOpen != Modal.UPGRADE)
            return null;
        return _jsx(UpgradeDialog, { appId: config.adminCenterAppId, appInsight: appInsightInstance });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Ref, { innerRef: logic.onRenderNavbarHtmlElement, children: _jsx(Flex, { gap: "gap.small", padding: "padding.medium", vAlign: "center", children: _jsxs(Flex, { fill: true, gap: "gap.small", vAlign: "center", column: isOnMobile, children: [calendarNavigation, securityModeAlert, _jsx(FlexItem, { push: !isOnMobile, children: _jsxs(Flex, { gap: "gap.small", children: [props.displayedCalendars.length > 0 && allowSync && calendarsDropdownFilter, tagsDropdownFilter, timezoneDropdownFilter] }) }), _jsxs(Flex, { hAlign: "end", gap: "gap.small", children: [newEventButton, viewModeButton, moreOptionsButton, helpButton()] })] }) }) }), renderGetIdDialog(), renderNotificationDialog(), !!logic.state.imageToPrint && renderPrintIframe(), renderSettingsDialog(), renderUpgradeDialog(), renderRoomsDialog()] }));
};

import { useMutation, useQuery } from "react-query";
import * as microsoftTeams from "@microsoft/teams-js";
import { ErrorModule, handleExpiredToken, setAppConfiguration, useMsTeamsSelector, useTranslate } from "front";
import { useDispatch, useSelector } from "react-redux";
import { getCalendar, getCalendarTags, updateCalendar } from "../../apis/apiCalendar";
import { getResource, updateResource } from "../../apis/apiResource";
import { createEvent, deleteCustomOccurencesByGroupId, deleteEvent, deleteEventsByGroupId, updateEvent } from "../../apis/apiEvent";
import { AppView } from "../../interfaces/AppView/AppView";
import { translations } from "../../translations";
import { useOptionsSelector } from "../../redux/reducers/optionsReducer";
import { buisinessDayDefault } from "../../views/Configuration/Configuration.logic";
const loadCalendar = async () => {
    try {
        const config = await microsoftTeams.pages.getConfig();
        const calendarId = config.entityId;
        if (!calendarId) {
            //    ErrorModule.showErrorAlert("Can't retrieve calendar id");
            return { id: "false" };
        }
        const data = await getCalendar(calendarId);
        if (data.id !== calendarId)
            ErrorModule.showErrorAlert("Can't retrieve calendar");
        if (!data.businessDays) {
            data.businessDays = buisinessDayDefault;
        }
        return data;
    }
    catch (error) {
        //---------log in  out teams-----------
        const calendarId = process.env.REACT_APP_CALENDAR_MOCK_ID;
        if (!calendarId) {
            //    ErrorModule.showErrorAlert("Can't retrieve calendar id");
            return;
        }
        const data = await getCalendar(calendarId);
        if (data.id !== calendarId)
            ErrorModule.showErrorAlert("Can't retrieve calendar");
        return data;
        //---------log in out teams-----------
    }
};
const loadResources = async (calendar) => {
    if (calendar?.id && calendar.resourceMode) {
        try {
            const resources = await getResource(calendar.id);
            return resources.resourceData;
        }
        catch (e) {
            return new Array();
        }
    }
    return new Array();
};
const addEvent = async (data) => {
    const params = { calendarId: data.calendarId, event: data.event, allowDoubleBooking: data.allowDoubleBooking };
    let evt = null;
    evt = await createEvent(params);
    return evt;
};
const changeEvent = async (data) => {
    if (!data.event.id)
        throw new Error("can't update event without id");
    const params = { calendarId: data.calendarId, eventId: data.event.id, event: data.event, allowDoubleBooking: data.allowDoubleBooking };
    let evt = null;
    evt = await updateEvent(params);
    return evt;
};
const delEvent = async (data) => {
    if (!data.event.id)
        throw new Error("can't delete event without id");
    if (!data.deleteOnlyOccurence && !!data.event.groupId)
        await deleteEventsByGroupId(data.event.calendarId, data.event.groupId);
    await deleteEvent(data.calendarId, data.event.id);
};
const delOccurenceGroupEvent = async (data) => {
    if (!data.event.id)
        throw new Error("can't delete event without id");
    if (!!data.event.groupId)
        await deleteCustomOccurencesByGroupId(data.event.calendarId, data.event.groupId);
};
const updatedCalendar = async (calendar) => {
    if (!calendar.id)
        return;
    await updateCalendar(calendar.id, calendar);
    return;
};
const emptyfunction = (data) => new Promise((res) => res(data.tags));
export const useCalendarCache = () => {
    const dispatch = useDispatch();
    const t = useTranslate(translations);
    const { nameApp } = useOptionsSelector("nameApp");
    const { tabName } = useMsTeamsSelector("tabName");
    const { queryClient, appViews } = useSelector((s) => s.infosApp);
    const invalidateEvents = () => {
        queryClient.invalidateQueries({ queryKey: ["events"] });
    };
    const { data: calendar, isLoading: calendarLoading, refetch: refecthCalendar, } = useQuery("calendar", loadCalendar, {
        staleTime: Infinity,
        ...handleExpiredToken,
        onSuccess: (res) => {
            const appPathName = appViews[AppView.Calendars]?.path.split("/")[1] ?? "";
            const appOwner = res?.createdBy ?? "";
            if (!!tabName && res && tabName != res?.name)
                mutateCalendar({ ...res, name: tabName });
            dispatch(setAppConfiguration({ appPathName, appId: res?.id ?? "", appOwner, appName: !!tabName ? tabName : res?.name, app: t(nameApp), from: nameApp }));
        },
    });
    const { mutate: mutateCalendar, isLoading: mutateCalendarIsLoading } = useMutation(updatedCalendar, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueryData("calendar", variable);
        },
    });
    const { data: resources, isLoading: resourcesLoading, refetch: refetchRessource, } = useQuery("resources", () => loadResources(calendar), {
        staleTime: Infinity,
        enabled: !!calendar,
        ...handleExpiredToken,
    });
    const { mutate: mutateResource, isLoading: resourcesUpdateLoading } = useMutation((data) => updateResource(data.id, data.calendarId, data.resource), {
        ...handleExpiredToken,
        onSuccess: (_, variable) => {
            queryClient.setQueryData("resources", [...variable.resource.resourceData]);
        },
    });
    const { mutate: mutateAddTags } = useMutation(emptyfunction, {
        ...handleExpiredToken,
        onSuccess: (data) => {
            queryClient.setQueryData("calendar", (oldData) => {
                const oldsTags = oldData?.tags ? oldData?.tags : [];
                return { ...oldData, tags: [...oldsTags, ...data] };
            });
        },
    });
    const { mutate: mutateTags } = useMutation(getCalendarTags, {
        ...handleExpiredToken,
        onSuccess: (data) => {
            queryClient.setQueryData("calendar", (oldData) => ({ ...oldData, tags: data }));
        },
    });
    const { mutate: mutateCreateEvent } = useMutation(addEvent, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueriesData(["events"], (oldData) => {
                if (!oldData)
                    return [data];
                return [...oldData, data];
            });
        },
    });
    const { mutate: mutateUpdateEvent } = useMutation(changeEvent, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueriesData(["events"], (oldData) => {
                if (!oldData)
                    return [variable.event];
                const newEvents = oldData.map((d) => {
                    if (d.id == data.id) {
                        d = data;
                    }
                    return d;
                });
                return newEvents;
            });
        },
    });
    const { mutate: mutateDeleteEvent } = useMutation(delEvent, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueriesData(["events"], (oldData) => {
                if (!oldData)
                    return [];
                const newEvents = oldData.filter((d) => d.id != variable.event.id);
                newEvents.map((e) => {
                    if (e.groupId === variable.event.groupId && e.id !== variable.event.id)
                        e.groupId = "";
                });
                return newEvents;
            });
        },
    });
    const { mutate: mutateDeleteOccurenceGroupEvent } = useMutation(delOccurenceGroupEvent, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueriesData(["events"], (oldData) => {
                if (!oldData)
                    return [];
                const newEvents = oldData.filter((d) => d.id != variable.event.id);
                newEvents.map((e) => {
                    if (e.groupId === variable.event.groupId && e.id === variable.event.id)
                        e.groupId = "";
                });
                return newEvents;
            });
        },
    });
    const { mutate: mutateDeleteOccurenceOfSerieEvent } = useMutation(delOccurenceGroupEvent, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueriesData(["events"], (oldData) => {
                if (!oldData)
                    return [];
                const newEvents = oldData.filter((e) => !(e.groupId === variable.event.groupId && e.id !== variable.event.id));
                return newEvents;
            });
        },
    });
    return {
        calendar,
        calendarLoading,
        resources,
        mutateTags,
        mutateCreateEvent,
        mutateUpdateEvent,
        mutateDeleteEvent,
        mutateDeleteOccurenceGroupEvent,
        mutateDeleteOccurenceOfSerieEvent,
        mutateAddTags,
        mutateCalendar,
        refecthCalendar,
        invalidateEvents,
        refetchRessource,
        mutateResource,
        resourcesUpdateLoading,
        mutateCalendarIsLoading,
    };
};

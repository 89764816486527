import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLogic } from "./Event.logic";
import { Alert, Dialog, Flex, FlexItem, Text } from "@fluentui/react-northstar";
import { Modal, hourString, useAppConfigurationSelector, useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { EventForm } from "./Form/EventFormComponent";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import { IEventSelection } from "../../interfaces/Enums";
import { EventNavbar } from "./NavBar/EventNavbar";
import EventAboutDialog from "../../components/dialogs/Event/EventAboutDialog";
import { EventConflictDialog } from "../dialogs/Conflict/EventConflictDialog";
import moment from "moment";
export const EventComponent = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const { resources } = useCalendarCache();
    const { isOnMobile, locale } = useMsTeamsSelector("isOnMobile", "locale");
    const { from } = useAppConfigurationSelector("from");
    const getTitle = () => {
        const isEditMode = props.event ? !!props.event.event.title : false;
        const name = from;
        const map = {
            Calendar: isEditMode ? t("EditCalendarEvent") : t("AddCalendarEvent"),
            Parking: isEditMode ? t("EditReservation") : t("NewReservation"),
            Booking: isEditMode ? t("EditReservation") : t("NewReservation"),
        };
        return map[name];
    };
    /**
     * Render view title
     */
    const renderViewTitle = () => {
        const title = !!logic.state.event?.title ? logic.state.event?.title?.toUpperCase() : getTitle();
        return (_jsx(FlexItem, { children: _jsx(Text, { truncated: true, size: "larger", weight: "bold", content: title }) }));
    };
    /**
     * Render form for event properties
     */
    const renderForm = () => {
        return (_jsx(EventForm, { show: true, disabled: logic.state.isSavingEvent || logic.state.isDeletingEvent, event: logic.state.event, resources: resources ?? new Array(), onUpdateEvent: logic.onUpdateEvent, isEventOccurenceFromSerie: logic.state.isEventOccurenceFromSerie, updateAddress: logic.updateAddress }));
    };
    /**
     * Render delete dialog
     */
    const renderDeleteDialog = () => {
        if (!logic.state.showDeleteDialog)
            return null;
        let deleteHeader = !!logic.state.event.groupId ? "HeaderDeleteModalSerie" : "HeaderDeleteModal";
        if (logic.state.isEventOccurenceFromSerie) {
            deleteHeader = logic.state.deleteChoice === IEventSelection.Occurrence ? "HeaderDeleteModalOccurence" : "HeaderDeleteModalSerie";
        }
        return (_jsx(Dialog, { closeOnOutsideClick: false, header: t(deleteHeader), content: t("ContentDeleteModal"), open: logic.state.showDeleteDialog, confirmButton: t("Delete"), cancelButton: t("Cancel"), onCancel: logic.closeDeleteModal, onConfirm: logic.onConfirmDelete }));
    };
    /**
     * Render discard dialog
     */
    const renderDiscardDialog = () => {
        if (!logic.state.showDiscardChangesDialog)
            return null;
        return (_jsx(Dialog, { closeOnOutsideClick: false, header: t("DiscardMessageTitle"), cancelButton: t("DiscardChanges"), confirmButton: t("ContinueEditing"), onCancel: logic.onClose(true), onConfirm: logic.closeDiscardChangesDialog, open: logic.state.showDiscardChangesDialog, content: t("DiscardMessage") }));
    };
    /**
     * Render warning when editing a serie with custom occurences
     */
    const renderWarningUpdateRecurrence = () => {
        return (_jsx(Dialog, { closeOnOutsideClick: false, header: t("LabelWarning"), cancelButton: t("Cancel"), confirmButton: t("Save"), onCancel: logic.closeWarningUpdateRecurrenceDialog, onConfirm: () => logic.onSaveEvent(), open: logic.state.showWarningUpdateRecurrenceDialog, content: t("WarningUpdateRecurrenceMessage") }));
    };
    /**
     * Render navigation bar
     * @param readOnly
     */
    const renderNavBar = (readOnly) => {
        const isSaveButtonDisabled = logic.isSaveButtonDisabled() || readOnly;
        return (_jsx(EventNavbar, { readOnly: readOnly, isSaveButtonDisabled: isSaveButtonDisabled, eventId: logic.state.event.id, isEventOccurenceFromSerie: logic.state.isEventOccurenceFromSerie, isSavingEvent: logic.state.isSavingEvent, onSaveEvent: logic.onSaveEvent, isDeletingEvent: logic.state.isDeletingEvent, onClickDeleteButton: logic.onClickDeleteButton, onClose: logic.onClose, showAboutDialog: logic.showAboutDialog, deleteEventOccurenceOrSerie: logic.deleteEventOccurenceOrSerie, onShareEvent: logic.onShareEvent, onSeeConflicts: logic.onSeeConflicts, conflictDatesString: logic.state.conflictDatesString }));
    };
    const renderErrorMessage = () => (_jsx(Flex, { fill: true, children: _jsx(Alert, { danger: true, style: { width: "100%" }, children: _jsxs(Flex, { column: true, children: [_jsx(Flex, { children: logic.state.errorMessage }), logic.state.optionsEvent && logic.state.optionsEvent.length > 0 && (_jsxs(Flex, { styles: { color: '#6264A7 !important' }, style: { color: '#6264A7 !important' }, children: [_jsx("span", { style: { color: '#6264A7 !important' }, children: t("Suggested") }), logic.state.optionsEvent.map((event, index) => {
                                return (_jsxs("span", { onClick: () => logic.handleTimeClick(index), style: { textDecoration: 'underline', cursor: 'pointer', margin: '0 5px', color: '#6264A7 !important' }, children: [hourString(moment(event.startDate).tz(event.timeZone), locale), "-", hourString(moment(event.endDate).tz(event.timeZone), locale)] }, index));
                            })] }))] }) }) }));
    /**
     * Render dialog about event informations
     */
    const renderAboutDialog = () => {
        if (!logic.state.showAboutDialog)
            return null;
        return _jsx(EventAboutDialog, { show: logic.state.showAboutDialog, event: logic.state.event, onHide: logic.closeAboutDialog });
    };
    return (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", className: "fill-absolute", children: [_jsx(FlexItem, { children: _jsxs(Flex, { column: true, vAlign: "center", gap: "gap.small", padding: "padding.medium", children: [_jsxs(Flex, { children: [renderViewTitle(), !isOnMobile && renderNavBar(!logic.userCanEditEvent)] }), logic.state.errorMessage && renderErrorMessage()] }) }), _jsx(FlexItem, { children: _jsx(Flex, { column: true, styles: { overflowY: "scroll" }, padding: "padding.medium", children: renderForm() }) }), isOnMobile && renderNavBar(!logic.userCanEditEvent), renderDiscardDialog(), renderDeleteDialog(), renderAboutDialog(), renderWarningUpdateRecurrence(), logic.isOpen === Modal.EVENT_CONFLICT && (_jsx(EventConflictDialog, { conflictDatesString: logic.state.conflictDatesString, onSaveEvent: logic.onSaveEvent }))] }));
};
